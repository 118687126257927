<template>
  <v-row class="fill-height elevation-1 calendario">
    <Agendar />
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn outlined class="mr-4" @click="setToday">Hoje</v-btn>
          <v-btn fab text small @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :color="$primary"
            dark
            class="mr-6"
            @click="$eventBus.$emit('AgendarDialog', null, true)"
          >Agendar</v-btn>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn outlined v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet style="min-height: 900px">
        <v-calendar
          style="min-height: 900px;"
          ref="calendar"
          v-model="focus"
          locale="pt"
          color="primary"
          :events="getItems"
          :event-color="getEventColor"
          :event-margin-bottom="4"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="selectedOpen = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions v-if="selectedEvent.tipo==1" class="d-flex fluid justify-end">
              <v-btn
                @click="$eventBus.$emit('AgendarDialog', selectedEvent, true)"
                :color="selectedEvent.color"
                icon
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
import STATES from "@/mixins/_STATES"
import _COLORS from "@/store/_COLORS"
export default {
  data: () => ({
    today: "2020-01-01",
    focus: "2020-01-01",
    type: "month",
    typeToLabel: {
      month: "Mês",
      week: "Semana",
      day: "Dia"
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    agendar: false
  }),
  mixins: [STATES],
  components: {
    Agendar: () => import("@/components/dialogs/Agendar")
  },
  created() {
    this.today = this.focus = this.$moment().format("YYYY-MM-DD")
  },
  computed: {
    getItems() {
      return this.$store.getters["at/getAll"]
        .filter(({data}) => !!data)
        .map(el => {
          return {
            name: `${el.nome} - ${this.STATES.at[el.estado].nome}`,
            details: el.obs || "Sem notas",
            start: el.hora ? `${el.data} ${el.hora}` : `${el.data}`,
            color: this.STATES.at[el.estado].color,
            tipo: 0
          }
        })
        .concat(
          this.$store.getters["agenda/getAll"].map(el => {
            let obra = this.$store.getters["obra/getObra"](el.id_obra)
            return {
              name:
                this.$store.getters["utilizador/getById"](el.id_utilizador) +
                " - " +
                obra.nome,
              start: `${el.data} ${el.inicio}`,
              end: `${el.data} ${el.fim}`,
              color: _COLORS[Math.floor(Math.random() * _COLORS.length)],
              details: `${obra.morada} ${obra.localidade} ${obra.cp}`,
              tipo: 1,
              data: el.data,
              fim: el.fim,
              inicio: el.inicio,
              id_obra: el.id_obra,
              id_utilizador: el.id_utilizador,
              id_agenda: el.id_agenda
            }
          })
        )
    },
    title() {
      const { start, end } = this
      if (!start || !end) {
        return ""
      }

      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? "" : endMonth

      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? "" : endYear

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`
        case "week":
          return `${start.day} ${startMonth} ${startYear} - ${end.day} ${suffixMonth} ${suffixYear}`
        case "day":
          return `${start.day} ${startMonth} ${startYear}`
      }
      return ""
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long"
      })
    }
  },
  mounted() {
    this.$refs.calendar.checkChange()
  },
  methods: {
    viewDay({ date }) {
      this.focus = date
      this.type = "day"
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = this.today
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => (this.selectedOpen = true), 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange({ start, end }) {
      this.start = start
      this.end = end
    }
  }
}
</script>
<style scoped>
.calendario {
  background-color: #fff !important;
  padding: 25px;
  margin: 0px !important;
}
</style>
